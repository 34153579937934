import { catalog } from "./catalog";
import { store } from "./store";
import { banner } from "./banner";
import { common } from "./common";
import { staff } from "./staff";
import { order } from "./order";

export default ({ id }: { id: string }) => {
  const contents = [
    ...banner,
    ...store,
    ...catalog,
    ...common,
    ...staff,
    ...order
  ]
  return contents.find(content => content.id === id) || contents[0];
}