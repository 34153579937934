import { Modal } from "@/interfaces/modal/modal.dto";

export const order: Array<Modal.Content> = [
  {
    id: 'admin-order-processed',
    title: 'Обработать заказ',
    action: 'delete',
    request: '/api/order/processed',
    emit: 'admin-order-edit',
    method: 'patch',
    message: 'перевести заказ в собранные',
    cancelName: 'Нет',
    submitName: 'Да'
  }
];