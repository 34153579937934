import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'EditIcon',
  template: `
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14.7857 9.00001C14.4306 9.00001 14.1428 9.28783 14.1428 9.64288V16.0714C14.1428 16.4265 13.855 16.7143 13.5 16.7143H1.92857C1.57352 16.7143 1.2857 16.4265 1.2857 16.0714V3.2143C1.2857 2.85925 1.57352 2.57143 1.92857 2.57143H9.64285C9.99791 2.57143 10.2857 2.28361 10.2857 1.92856C10.2857 1.57351 9.99791 1.28573 9.64285 1.28573H1.92857C0.86345 1.28573 0 2.14918 0 3.2143V16.0714C0 17.1365 0.86345 18 1.92857 18H13.5C14.5651 18 15.4286 17.1365 15.4286 16.0714V9.64284C15.4286 9.28783 15.1408 9.00001 14.7857 9.00001Z" fill="#827F9C"/>
  <path d="M17.2796 0.720889C16.818 0.259237 16.1919 -6.51642e-05 15.5391 1.01706e-05C14.8859 -0.0018732 14.2592 0.257881 13.7989 0.721303L5.33118 9.18833C5.26093 9.25911 5.20793 9.34514 5.17625 9.43969L3.89055 13.2968C3.77833 13.6337 3.96046 13.9977 4.29732 14.1099C4.36267 14.1317 4.43111 14.1428 4.49997 14.1428C4.56897 14.1427 4.63757 14.1317 4.70311 14.1101L8.56026 12.8244C8.65499 12.7927 8.74106 12.7395 8.81161 12.6688L17.2793 4.20109C18.2404 3.24012 18.2405 1.68197 17.2796 0.720889ZM16.3703 3.29271L8.01317 11.6499L5.51631 12.4836L6.34752 9.98997L14.7079 1.63285C15.1675 1.17414 15.912 1.17489 16.3707 1.63451C16.5898 1.85407 16.7133 2.15131 16.7142 2.4615C16.715 2.77335 16.5912 3.07258 16.3703 3.29271Z" fill="#827F9C"/>
  </svg>
  `
});

export default script;