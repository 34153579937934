
import { defineComponent, PropType } from "vue-demi";
import { CDEKInfo, CDEKWidgetProps } from "@/store/common/cdek";
import { Cart } from "@/interfaces/cart/cart.dto";
import { dynamicsObject } from "@/interfaces";
import { PRODUCT_TYPES } from "@/utils/enums";
import { mapGetters } from "vuex";

export default defineComponent({
  emits: ['selectedDelivery'],
  name: "DeliveryCdek",
  props: {
    cart: {
      type: Object as PropType<Cart.Dto>,
      required: true,
    },
  },
  data() {
    return {
      deliveryInfo: {
        city: '',
        cityCode: '',
        address: '',
        term: '',
        type: 'office',
        price: '',
        workTime: '',
        phone: '',
        tarif: 15,
        point: '',
        postamat: false,
      }
    }
  },
  mounted() {
    const params = {
      goods: this.totalProducts(),
      onChoose: this.onChoose,
      /*onChooseProfile: this.onChooseProfile,
      onChooseAddress: this.onChooseAddress,*/
    };
    const Window: any = window;
    new Window.CDEKWidget(Object.assign(params, CDEKWidgetProps(this.user?.city)));
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    totalProducts() {
      let products = [] as Array<dynamicsObject>;
      for (const { product, quantity } of this.cart.products) {
        const units = product.type === PRODUCT_TYPES.UNIT ? 1 : product.unitsPerPack;

        const length = Math.round(product.length / 10);
        const width = Math.round(product.width / 10);
        const height = Math.round(product.height / 10);
        const weight = product.weight;

        const array = Array(units).fill({
          length, width, height, weight
        });

        for (let i = 0; i < quantity; i++) {
          products = [...products, ...array];
        }
        // products = [...products, ...Array(quantity).fill({
        //   length, width, height, weight
        // })];
      }
      return products;
    },
    //onChoose(info: CDEKInfo) {
    onChoose(type: any, tariff: any, addr: any) {
      console.log(addr);
      this.deliveryInfo = {
        city: addr.city,
        cityCode: (type == 'door') ? 'no': addr.city_code,
        postamat: (addr.type == 'POSTAMAT') ? true : false,
        tarif: tariff.tariff_code,
        address: (type == 'door') ? addr.formatted : addr.address,
        term: tariff.period_min+'-'+tariff.period_max,
        price: tariff.delivery_sum,
        type: type,
        point: addr.code,
        workTime: addr.work_time,
        phone: this.$store.getters.user?.phone || '',
      }
      this.$emit('selectedDelivery', this.deliveryInfo);
    },
    /*onChooseProfile(info: { price: string }) {
      this.deliveryInfo.type = 'courier';
      this.deliveryInfo.price = '';
      this.$emit('selectedDelivery', this.deliveryInfo);
    },
    onChooseAddress(info: any) {
      console.log(info, 2);
    },*/
  },
});
