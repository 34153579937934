import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import moduleAuthentication from './modules/authentication';
import moduleNotificaiton from './modules/notification';
import moduleCommon from './modules/common';
import moduleModal from './modules/modal';

export default createStore({
  state: {
    pendingRequest: false
  },
  mutations: {
    togglePendingRequest(state, status) {
      state.pendingRequest = status;
    }
  },
  actions: {},
  getters: {
    pendingRequest: (state) => state.pendingRequest
  },
  modules: {
    moduleNotificaiton,
    moduleAuthentication,
    moduleCommon,
    moduleModal,
  },
  plugins: [
    createPersistedState({
      paths: ["moduleAuthentication"],
    }),
  ],
});


