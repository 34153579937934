import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_common_drop_element = _resolveComponent("common-drop-element")!
  const _component_common_modal = _resolveComponent("common-modal")!
  const _component_common_calendar = _resolveComponent("common-calendar")!
  const _component_common_delivery = _resolveComponent("common-delivery")!
  const _component_notification = _resolveComponent("notification")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.drop)
          ? (_openBlock(), _createBlock(_component_common_drop_element, {
              key: 0,
              drop: _ctx.drop
            }, null, 8, ["drop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.modal)
          ? (_openBlock(), _createBlock(_component_common_modal, {
              key: 0,
              modal: _ctx.modal
            }, null, 8, ["modal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.calendar)
          ? (_openBlock(), _createBlock(_component_common_calendar, {
              key: 0,
              calendar: _ctx.calendar
            }, null, 8, ["calendar"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.delivery)
          ? (_openBlock(), _createBlock(_component_common_delivery, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_notification)
  ], 64))
}