import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ShopCartIcon',
  props: ['color'],
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.0026 16.9971C17.3525 16.9971 16.0101 18.3395 16.0101 19.9896C16.0101 21.6396 17.3526 22.9821 19.0026 22.9821C20.6527 22.9821 21.9951 21.6396 21.9951 19.9896C21.9951 18.3395 20.6527 16.9971 19.0026 16.9971ZM19.0026 21.1866C18.3425 21.1866 17.8056 20.6497 17.8056 19.9896C17.8056 19.3294 18.3425 18.7926 19.0026 18.7926C19.6628 18.7926 20.1996 19.3294 20.1996 19.9896C20.1996 20.6497 19.6628 21.1866 19.0026 21.1866Z" :fill="color || '#1D1D1D'"/>
  <path d="M23.8092 5.43182C23.6392 5.21427 23.3786 5.08738 23.1024 5.08738H5.54159L4.73361 1.70674C4.63695 1.30277 4.27573 1.01758 3.86037 1.01758H0.897755C0.401909 1.01753 0 1.41944 0 1.91529C0 2.41113 0.401909 2.81304 0.897755 2.81304H3.15175L6.06946 15.0214C6.16611 15.4257 6.52733 15.7105 6.9427 15.7105H20.9179C21.3305 15.7105 21.6902 15.4292 21.7893 15.0288L23.9738 6.20086C24.0399 5.93278 23.9792 5.64937 23.8092 5.43182ZM20.2155 13.9151H7.65131L5.97069 6.88289H21.9553L20.2155 13.9151Z" :fill="color || '#1D1D1D'"/>
  <path d="M8.1397 16.9971C6.4896 16.9971 5.14719 18.3395 5.14719 19.9896C5.14719 21.6396 6.48965 22.9821 8.1397 22.9821C9.78976 22.9821 11.1322 21.6396 11.1322 19.9896C11.1322 18.3395 9.78976 16.9971 8.1397 16.9971ZM8.1397 21.1866C7.47956 21.1866 6.9427 20.6497 6.9427 19.9896C6.9427 19.3294 7.47956 18.7926 8.1397 18.7926C8.79985 18.7926 9.33671 19.3294 9.33671 19.9896C9.33671 20.6497 8.79985 21.1866 8.1397 21.1866Z" :fill="color || '#1D1D1D'"/>
  </svg>
  `
});

export default script;