import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  getMethod: async (certificate_id: string) => {
    return await axios.get("/api/certificate/" + certificate_id);
  },
  createMethod: async (data: dynamicsObject) => {
    return await axios.post("/api/certificate", data);
  },
};
