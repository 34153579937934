import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import NProgress from 'nprogress'
import '@/assets/css/process.css';

import store from "@/store";
import { ROLES } from "@/utils/enums";

const routes: Array<RouteRecordRaw> = [
  // Auth
  { path: '/auth', name: 'Auth', meta: { section: 'auth', layout: 'main' }, component: () => import('../views/auth/Main.vue') },
  { path: '/logout', name: 'Logout', meta: { section: 'main', layout: 'main' }, component: () => import('../views/auth/Logout.vue') },
  { path: '/reset', name: 'Reset', meta: { section: 'auth', layout: 'main' }, component: () => import('../views/auth/Reset.vue') },
  { path: '/registration', name: 'Registration', meta: { section: 'auth', layout: 'main' }, component: () => import('../views/auth/Registration.vue') },
  { path: '/registration/:token', name: 'RegistrationToken', meta: { section: 'auth', layout: 'main' }, component: () => import('../views/auth/RegistrationToken.vue'), props: true },

  // Main
  { path: '/', name: 'Main', meta: { section: 'main', layout: 'main' }, component: () => import('../views/main/Main.vue') },
  { path: '/catalog', name: 'Catalog', meta: { section: 'catalog', layout: 'main' }, component: () => import('../views/main/Catalog.vue') },
  { path: '/catalog/product/:product_id', name: 'CatalogProduct', meta: { section: 'catalog', layout: 'main' }, component: () => import('../views/main/CatalogProduct.vue'), props: true },
  { path: '/partner', name: 'Partner', meta: { section: 'partner', layout: 'main' }, component: () => import('../views/main/Partner.vue') },
  { path: '/about', name: 'About', meta: { section: 'about', layout: 'main' }, component: () => import('../views/main/About.vue') },
  { path: '/cart', name: 'Cart', meta: { section: 'cart', layout: 'main' }, component: () => import('../views/main/Cart.vue') },
  { path: '/order', name: 'Order', meta: { section: 'cart', layout: 'main' }, component: () => import('../views/main/Order.vue') },

  // Profile
  { path: '/profile', name: 'Profile', meta: { requiredAuth: true, section: 'profile', layout: 'main' }, component: () => import('../views/profile/Main.vue') },
  { path: '/profile/order', name: 'ProfileOrder', meta: { requiredAuth: true, section: 'profile', layout: 'main' }, component: () => import('../views/profile/Order.vue') },
  { path: '/profile/analytics', name: 'ProfileAnalytics', meta: { requiredAuth: true, section: 'profile', layout: 'main' }, component: () => import('../views/profile/Analytics.vue') },
  { path: '/profile/link', name: 'ProfileLink', meta: { requiredAuth: true, section: 'profile', layout: 'main' }, component: () => import('../views/profile/Link.vue') },
  { path: '/profile/finance', name: 'ProfileFinance', meta: { requiredAuth: true, section: 'profile', layout: 'main' }, component: () => import('../views/profile/Finance.vue') },
  { path: '/profile/education', name: 'ProfileEducation', meta: { requiredAuth: true, section: 'profile', layout: 'main' }, component: () => import('../views/profile/Education.vue') },
  
  { path: '/partner/test', name: 'PartnerTest', meta: { requiredAuth: true, roles: [ROLES.PARTNER], section: 'partner', layout: 'main' }, component: () => import('../views/partner/Test.vue') },

  // Admin
  { path: '/admin/catalog', name: 'AdminCatalog', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Catalog.vue') },
  { path: '/admin/store', name: 'AdminStore', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Store.vue') },
  { path: '/admin/order', name: 'AdminOrder', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Order.vue') },
  { path: '/admin/partner', name: 'AdminPartner', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Partner.vue') },
  { path: '/admin/client', name: 'AdminClient', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Client.vue') },
  { path: '/admin/setting', name: 'AdminSetting', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Setting.vue') },
  { path: '/admin/banner', name: 'AdminBanner', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Banner.vue') },
  { path: '/admin/chat', name: 'AdminChat', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Chat.vue') },
  { path: '/admin/profile', name: 'AdminProfile', meta: { requiredAuth: true, roles: [ROLES.OWNER, ROLES.ADMIN], section: 'admin', layout: 'admin' }, component: () => import('../views/admin/Profile.vue') },

  { path: "/admin", redirect: '/admin/catalog' },
  { path: "/admin/:catchAll(.*)", redirect: '/admin/catalog' },
  { path: "/:catchAll(.*)", redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (to.name) NProgress.start();
  store.commit('destroyModal');

  if (to.matched.some((route) => route.meta?.section === 'auth'))
    return store.getters.user ? next('/') : next();

  if (to.matched.some((route) => route.meta?.requiredAuth && route.meta?.roles))
    return (to.meta?.roles as Array<ROLES>).includes(store.getters.user?.role) ? next() : next('/');

  if (to.matched.some((route) => route.meta?.requiredAuth))
    return store.getters.user ? next() : next('/');

  next();
});

router.afterEach(() => {
  NProgress.done();
})

export default router;
