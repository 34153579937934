import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'MinusIcon',
  props: ['color'],
  template: `
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 6L-1.19209e-07 6" :stroke="color || 'white'"/>
  </svg>
  `
});

export default script;