import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const catalog = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'admin-category',
      data: {
        name: ''
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название категории', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-category-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        image: data?.image,
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название категории', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-category-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
    {
      id: 'admin-catalog',
      data: {
        name: '',
        category: '',
        weight: '',
        height: '',
        width: '',
        length: '',
        type: '',
        pricePerUnit: '',
        unitsPerPack: '',
        pricePerPack: '',
        description: '',
        attachment: [],
        files: [],
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название товара', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Категория *', placeholder: 'Категория', id: 'category', grid: '1 / 4', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Вес *', placeholder: '0 г.', id: 'weight', grid: '4 / 7', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Тип *', placeholder: 'В упаковке', id: 'type', grid: '7 / 11', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Длина *', placeholder: '0 мм.', id: 'length', grid: '1 / 4', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Ширина *', placeholder: '0 мм.', id: 'width', grid: '4 / 7', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Высота *', placeholder: '0 мм.', id: 'height', grid: '7 / 11', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Стоимость за ед. *', placeholder: '100', id: 'pricePerUnit', grid: '1 / 4', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Ед. в упаковке', placeholder: '100', id: 'unitsPerPack', grid: '4 / 7', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Стоимость за уп.', placeholder: '100', id: 'pricePerPack', grid: '7 / 11', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Описание', placeholder: 'Введите описание товара', id: 'description', grid: '1 / 11', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Изображения', placeholder: '', id: 'attachment', grid: '1 / 11', type: 'files', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-catalog-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        category: data?.category?.name,
        category_id: data?.category?._id,
        weight: data?.weight,
        height: data?.height,
        width: data?.width,
        length: data?.length,
        type: data?.type,
        type_id: data?.type,
        pricePerUnit: data?.pricePerUnit,
        unitsPerPack: data?.unitsPerPack,
        pricePerPack: data?.pricePerPack,
        description: data?.description,
        attachment: data?.image,
        files: data?.image
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название товара', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Категория *', placeholder: 'Категория', id: 'category', grid: '1 / 4', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Вес *', placeholder: '0 г.', id: 'weight', grid: '4 / 7', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Тип *', placeholder: 'В упаковке', id: 'type', grid: '7 / 11', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Длина *', placeholder: '0 мм.', id: 'length', grid: '1 / 4', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Ширина *', placeholder: '0 мм.', id: 'width', grid: '4 / 7', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Высота *', placeholder: '0 мм.', id: 'height', grid: '7 / 11', type: 'text', mask: '########', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Стоимость за ед. *', placeholder: '100', id: 'pricePerUnit', grid: '1 / 4', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Ед. в упаковке', placeholder: '100', id: 'unitsPerPack', grid: '4 / 7', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Стоимость за уп.', placeholder: '100', id: 'pricePerPack', grid: '7 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Описание', placeholder: 'Введите описание товара', id: 'description', grid: '1 / 11', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Изображения', placeholder: '', id: 'attachment', grid: '1 / 11', type: 'files', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-catalog-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}