import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'CancelIcon',
  template: `
  <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22.1023 7.07031L7.96016 21.2124" stroke="black" stroke-width="2"/>
  <path d="M22.1023 21.2148L7.96016 7.07271" stroke="black" stroke-width="2"/>
  </svg>
  `
});

export default script;