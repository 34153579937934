import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const banner = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'admin-banner',
      data: {
        name: '',
        description: '',
        discount: '',
        startedAt: '',
        endedAt: '',
        referral: false
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Описание *', placeholder: 'Введите описание', id: 'description', grid: '1 / 11', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '% скидки', placeholder: '10 %', id: 'discount', grid: '1 / 3', type: 'text', mask: '##', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Дата начача', placeholder: 'дд.мм.гг', id: 'startedAt', grid: '3 / 7', type: 'date', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Дата окончания', placeholder: 'дд.мм.гг', id: 'endedAt', grid: '7 / 11', type: 'date', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Реферальная программа', placeholder: '', id: 'referral', grid: '1 / 11', type: 'checkbox', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-banner-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        description: data?.description,
        discount: data?.discount,
        startedAt: data?.startedAt,
        endedAt: data?.endedAt,
        image: data?.image,
        referral: data?.referral,
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Описание *', placeholder: 'Введите описание', id: 'description', grid: '1 / 11', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '% скидки', placeholder: '10 %', id: 'discount', grid: '1 / 3', type: 'text', mask: '##', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Дата начача', placeholder: 'дд.мм.гг', id: 'startedAt', grid: '3 / 7', type: 'date', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Дата окончания', placeholder: 'дд.мм.гг', id: 'endedAt', grid: '7 / 11', type: 'date', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Реферальная программа', placeholder: '', id: 'referral', grid: '1 / 11', type: 'checkbox', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-banner-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}