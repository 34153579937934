import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const store = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'admin-store',
      data: {
        address: ''
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название склада', id: 'address', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-store-edit',
      data: {
        _id: data?._id,
        address: data?.address,
      },
      inputs: [
        { required: true, name: 'Название *', placeholder: 'Введите название склада', id: 'address', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-store-delete',
      data: {
        _id: data?._id,
        name: data?.address,
      },
      inputs: []
    },
    {
      id: 'admin-store-product',
      data: {
        product: '',
        store: '',
        quantity: '',
      },
      inputs: [
        { required: true, name: 'Товар *', placeholder: 'Выберите товара', id: 'product', grid: '1 / 11', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Склад * ', placeholder: 'Выберите склад', id: 'store', grid: '1 / 6', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Кол-во на складе * ', placeholder: '10', id: 'quantity', grid: '6 / 11', type: 'text', mask: '#######', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-store-product-edit',
      data: {
        _id: data?._id,
        product: data?.product?.name,
        product_id: data?.product?._id,
        store: data?.store?.address,
        store_id: data?.store?._id,
        quantity: data?.quantity,
      },
      inputs: [
        { required: true, name: 'Товар *', placeholder: 'Выберите товара', id: 'product', grid: '1 / 11', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Склад * ', placeholder: 'Выберите склад', id: 'store', grid: '1 / 6', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Кол-во на складе * ', placeholder: '10', id: 'quantity', grid: '6 / 11', type: 'text', mask: '#######', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-store-product-delete',
      data: {
        _id: data?._id,
        name: data?.product?.name,
      },
      inputs: []
    },
  ]
}