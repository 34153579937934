import { dynamicsObject } from '@/interfaces';
import { ROLES } from './enums';

export default (user: dynamicsObject) => {
  const routes = [
    { route: '/auth', role: ROLES.OWNER },
  ];
  const correctRoute = routes.find(route => route.role === user.role);
  if (!correctRoute) return;
  return correctRoute.route;
};