
import { defineComponent, PropType, defineAsyncComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { AxiosRequestConfig } from "axios";

import api from "@/api";
import { Modal } from "@/interfaces/modal/modal.dto";
import { Table } from "@/interfaces/table/table.dto";

import getModalContent from './datas/content';
import getModalInputs from './datas/inputs';
import getModalRequests from './datas/requests';
import { useAPI } from "@/use";

const ModalHeader = defineAsyncComponent(() => import('./modules/header/index.vue'))
const ModalContainer = defineAsyncComponent(() => import('./modules/container/index.vue'))
const ModalActions = defineAsyncComponent(() => import('./modules/actions/index.vue'))

export default defineComponent({
  name: 'ModalMain',
  props: {
    modal: {
      type: Object as PropType<Table.Main>,
      required: true
    }
  },
  data() {
    return {
      options: {} as Table.Main,
      data: {} as Modal.Main,
      datas: [],
    }
  },
  created() {
    this.options = this.modal;
    this.data = {
      content: getModalContent(this.options),
      inputs: getModalInputs(this.options),
    };
    getModalRequests(this.data, this.options, this.datas, (this.API as typeof api));
  },
  mounted() {
    document.addEventListener('keydown', this.keys);
  },
  unmounted() {
    document.removeEventListener('keydown', this.keys);
  },
  computed: {
    ...mapGetters(['pendingRequest']),
  },
  methods: {
    keys(ev: any) {
      if (ev.key === 'Escape') this.removeModal();
      if (ev.key === 'Enter' && !ev.shiftKey) this.submit();
    },
    async submit() {
      this.$store.commit('togglePendingRequest', true);
      const resultCheck = this.checkInputs();
      if (!resultCheck.status && resultCheck.showMessage) {
        this.$store.commit("createNotification", {
          status: "error",
          message: `Вам необходимо выбрать вариант из списка в поле "${resultCheck.input.name}"!`,
        });
      }
      if (!resultCheck.status) {
        return this.$store.commit('togglePendingRequest', false);
      }

      const newId = await this.mainRequest();

      if (this.data.content.fileRequest && this.data.inputs.data.file) {
        await this.updateFile(newId);
      }
      if ((this.data.content.fileRequest || this.data.content.id === 'admin-catalog' || this.data.content.id === 'admin-catalog-edit') && this.data.inputs.data.files) {
        await this.updateFiles(newId);
      }
      this.removeModal();
    },
    async mainRequest() {
      const options = this.collectOptions();      
      const result = await useAPI().modal.onSubmit(options);
      if (this.data.content.emit) this.emitter.emit(this.data.content.emit, result.data);
      return this.data.content.action === 'add' ? result.data._id : '';
    },
    async updateFile(_id: string) {
      const formData = new FormData();
      formData.append("file", this.data.inputs.data.file);
      formData.append("_id", _id || this.data.inputs.data._id);

      const options = this.collectFileOptions(formData, _id);        
      const resultFile = await this.API.modal.uploadFileMethod(options);
      this.emitter.emit(this.data.content.fileEmit || this.data.content.emit, resultFile.data);
    },
    async updateFiles(_id: string) {
      const formData = new FormData();
      formData.append("_id", _id || this.data.inputs.data._id);
      
      for (const file of this.data.inputs.data.files) {
        formData.append("newAttachment", file);
      }

      const options = this.collectFileOptions(formData, _id);   
      const resultFile = await this.API.modal.uploadFileMethod(options);
      this.emitter.emit(this.data.content.fileEmit || this.data.content.emit, resultFile.data);
    },
    collectOptions() {
      const options: AxiosRequestConfig = {
        method: this.data.content.method,
        url: `${this.data.content.request}${
          this.data.content.method === "delete"
            ? `/${this.data.inputs.data._id}`
            : ""
        }`,
      }
      if (this.data.content.method !== "delete") {
        options.data = this.data.inputs.data;
      }
      // if (this.data.content.method === "delete") {
      //   options.params = this.data.inputs.data;
      // }
      return options;
    },
    collectFileOptions(formData: FormData, _id: string) {
      let request = (this.data.content.id === 'admin-catalog' || this.data.content.id === 'admin-catalog-edit') ? '/api/file/product/image' : this.data.content.fileRequest;
      const options: AxiosRequestConfig = {
        method: this.data.content.fileMethod,
        url: `${request}${
          this.data.content.fileMethod === "delete"
            ? `/${_id || this.data.inputs.data._id}`
            : ""
        }`,
        headers: { "Content-Type": "multipart/form-data" }
      }
      if (this.data.content.fileMethod !== "delete") {
        options.data = formData;
        options.params = { _id: _id || this.data.inputs.data._id }
      }
      return options;
    },
    checkInputs() {
      for (const key in this.data.inputs.data) {
        const input = this.data.inputs.inputs.find(i => i.id === key);
        if (!input) continue;
        if (input.drop && !this.data.inputs.data[key + '_id'] && input.required && !input.uncheck) {
          input.error = true;
          return { status: false, input, showMessage: true };
        }
        let value = this.data.inputs.data[key];
        if (input.type === 'number') value = Number(value);
        if (!value && input.required) {
          input.error = true;
          return { status: false, input };
        } else {
          input.error = false;
        }
      }
      return { status: true };
    },
    removeModal() {
      this.$store.commit('destroyModal');
    },
  },
  components: {
    ModalHeader,
    ModalContainer,
    ModalActions
  }
})
