import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'TrashIcon',
  template: `
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.219 2.25H10.1252V1.6875C10.1252 0.75702 9.36822 0 8.43774 0H6.18774C5.25726 0 4.50024 0.75702 4.50024 1.6875V2.25H1.40649C0.631088 2.25 0.000244141 2.88084 0.000244141 3.65625V5.625C0.000244141 5.93564 0.252104 6.1875 0.562744 6.1875H0.870151L1.35612 16.3928C1.39904 17.294 2.13943 18 3.04168 18H11.5838C12.4861 18 13.2265 17.294 13.2694 16.3928L13.7553 6.1875H14.0627C14.3734 6.1875 14.6252 5.93564 14.6252 5.625V3.65625C14.6252 2.88084 13.9944 2.25 13.219 2.25ZM5.62524 1.6875C5.62524 1.37735 5.8776 1.125 6.18774 1.125H8.43774C8.74789 1.125 9.00024 1.37735 9.00024 1.6875V2.25H5.62524V1.6875ZM1.12524 3.65625C1.12524 3.50118 1.25142 3.375 1.40649 3.375H13.219C13.3741 3.375 13.5002 3.50118 13.5002 3.65625V5.0625C13.3269 5.0625 1.84359 5.0625 1.12524 5.0625V3.65625ZM12.1456 16.3393C12.1313 16.6397 11.8845 16.875 11.5838 16.875H3.04168C2.74092 16.875 2.49412 16.6397 2.47985 16.3393L1.99642 6.1875H12.6291L12.1456 16.3393Z" fill="#FF142D"/>
  <path d="M7.31274 15.75C7.62338 15.75 7.87524 15.4981 7.87524 15.1875V7.875C7.87524 7.56436 7.62338 7.3125 7.31274 7.3125C7.0021 7.3125 6.75024 7.56436 6.75024 7.875V15.1875C6.75024 15.4981 7.00207 15.75 7.31274 15.75Z" fill="#FF142D"/>
  <path d="M10.1252 15.75C10.4359 15.75 10.6877 15.4981 10.6877 15.1875V7.875C10.6877 7.56436 10.4359 7.3125 10.1252 7.3125C9.8146 7.3125 9.56274 7.56436 9.56274 7.875V15.1875C9.56274 15.4981 9.81457 15.75 10.1252 15.75Z" fill="#FF142D"/>
  <path d="M4.50024 15.75C4.81088 15.75 5.06274 15.4981 5.06274 15.1875V7.875C5.06274 7.56436 4.81088 7.3125 4.50024 7.3125C4.1896 7.3125 3.93774 7.56436 3.93774 7.875V15.1875C3.93774 15.4981 4.18957 15.75 4.50024 15.75Z" fill="#FF142D"/>
  </svg>
  `
});

export default script;