
import { defineComponent, PropType } from "vue-demi";
import { Calendar } from '@/interfaces/common/calendar';
import CalendarMain from './modules/CalendarMain.vue';
import { getTargetPosition } from "@/utils/calendar";

export default defineComponent({
  emits: ['actionTerms'],
  name: 'ModalCalendar',
  props: {
    calendar: {
      type: Object as PropType<Calendar>,
      required: true
    }
  },
  created() {
    const target = this.jq(this.calendar.options.target);
    this.calendar.options.position = getTargetPosition(target);
    this.calendar.date = new Date(this.calendar.date || new Date());
  },
  mounted() {
    document.addEventListener('keydown', this.keys);
    this.jq("body").bind("click", (e) => {
      if (this.jq(e.target).closest(this.calendar.options.target).length === 0 &&
          this.jq(e.target).closest(this.$refs.calendar as HTMLDivElement).length === 0 )
        this.$store.commit('destroyCalendar');
    });
  },
  unmounted() {
    document.removeEventListener('keydown', this.keys);
    this.jq("body").unbind("click");
  },
  methods: {
    selectedDate(date: Date) {
      this.calendar.date = date;
      this.actionTerms('update');
    },
    actionTerms(action: string) {
      this.emitter.emit(this.calendar.options.id, { action, date: this.calendar.date });
      this.$store.commit('destroyCalendar');
    },
    keys(ev: KeyboardEvent) {
      if (ev.key === 'Escape') this.$store.commit('destroyCalendar');
      if (ev.key === 'Enter') this.actionTerms('update');
    },
  },
  computed: {
    styles() {
      return `
        top: ${this.calendar.options.position.top}px; 
        left: ${this.calendar.options.position.left}px;
      `;
    },
  },
  components: {
    CalendarMain
  }
})
