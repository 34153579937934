import axios from "axios";

export default {
  updateImageMethod: async (data: FormData) => {
    return await axios.patch("/api/file/banner", data);
  },
  updateAvatarUserMethod: async (data: FormData) => {
    return await axios.patch("/api/file/user/avatar", data);
  },
  deleteAvatarUserMethod: async () => {
    return await axios.delete("/api/file/user/avatar");
  },
  deleteProductImageMethod: async (image: string) => {
    return await axios.delete("/api/file/product/image/" + image);
  },
};
