
import { defineComponent, defineAsyncComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { mainSocketFollow, socketJoinRoom } from '@/socket';

const AdminLayout = defineAsyncComponent(() => import('./layouts/AdminLayout.vue'));
const MainLayout = defineAsyncComponent(() => import('./layouts/MainLayout.vue'));
const Notification = defineAsyncComponent(() => import('./components/common/notification/index.vue'));
import CommonDropElement from "@/components/common/drop/DropElement.vue";
import CommonModal from "@/components/common/modal/index.vue";
import CommonCalendar from "@/components/common/calendar/index.vue";
import CommonDelivery from "@/components/common/delivery/index.vue";

export default defineComponent({
  created() {
    this.$store.dispatch("checkAuthentication");
    this.socket.on('connect', async () => {
      socketJoinRoom(this.socket, this.socket.id, 'global-action');
      mainSocketFollow(this);
    });
  },
  computed: {
    ...mapGetters(["modal", "drop", "calendar", "delivery"]),
    layout() {
      const isRequiredAuth = this.$route.meta.layout || 'main';
      return isRequiredAuth + "-layout";
    },
  },
  components: {
    MainLayout,
    AdminLayout,
    Notification,
    CommonDropElement,
    CommonCalendar,
    CommonModal,
    CommonDelivery
  }
})
