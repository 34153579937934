import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'CopyIcon',
  template: `
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="3.5" width="10" height="13" rx="1.5" stroke="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1H5C4.44772 1 4 1.44772 4 2V3.5C4 3.73704 8.5 4 10.5 4C10.5 7 10.1848 13 10.5 13H12C12.5523 13 13 12.5523 13 12V2C13 1.44772 12.5523 1 12 1ZM5 0C3.89543 0 3 0.895431 3 2V3.5C3 3.96184 8.61808 2.53382 10 4.5C11.9232 7.23625 9.85727 14 10.5 14H12C13.1046 14 14 13.1046 14 12V2C14 0.895431 13.1046 0 12 0H5Z" fill="white"/>
  </svg>
  `
});

export default script;

