import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'StoreIcon',
  template: `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.8281 0V9.41406H10.5859V0H9.41406V9.41406H1.17188V0H0V20H1.17188V10.5859H9.41406V20H10.5859V10.5859H18.8281V20H20V0H18.8281Z" fill="#FFD249"/>
  <path d="M8.24219 1.17188H2.34375V8.24219H8.24219V1.17188ZM7.07031 7.07031H3.51562V2.34375H4.70703V3.51562H5.87891V2.34375H7.07031V7.07031Z" fill="#FFD249"/>
  <path d="M17.6562 1.17188H11.7578V8.24219H17.6562V1.17188ZM16.4844 7.07031H12.9297V2.34375H14.1211V3.51562H15.293V2.34375H16.4844V7.07031Z" fill="#FFD249"/>
  <path d="M2.34375 18.8281H8.24219V11.7578H2.34375V18.8281ZM3.51562 12.9297H4.70703V14.1016H5.87891V12.9297H7.07031V17.6562H3.51562V12.9297Z" fill="#FFD249"/>
  <path d="M11.7578 18.8281H17.6562V11.7578H11.7578V18.8281ZM12.9297 12.9297H14.1211V14.1016H15.293V12.9297H16.4844V17.6562H12.9297V12.9297Z" fill="#FFD249"/>
  </svg>
  `
});

export default script;