export const CERTIFICATE_INPUTS = {
  inputs: [
    { show: true, id: 'name', name: 'Имя', placeholder: 'Введите имя', type: 'text', grid: '1 / 6' },
    { show: true, id: 'phone', name: 'Телефон', placeholder: 'Введите телефон', type: 'text', mask: '+7(###)###-##-##', grid: '6 / 11' },
    { show: true, id: 'email', name: 'Email', placeholder: 'Введите почту', type: 'text', grid: '1 / 6' },
    { show: true, id: 'price', name: 'Цена', placeholder: 'Введите цену подарочной карты', type: 'text', mask: '#########', grid: '6 / 11' },
  ],
  data: {
    email: '',
    name: '',
    phone: '',
    price: ''
  }
}

export const PRODUCT_TYPE_DROP = [
  { _id: 'PACK', name: 'В упаковке' },
  { _id: 'UNIT', name: 'Поштучно' },
]