import auth from './modules/auth';
import user from './modules/user';
import chat from './modules/chat';
import file from './modules/file';
import modal from './modules/modal';
import table from './modules/table';
import order from './modules/order';
import banner from './modules/banner';
import catalog from './modules/catalog';
import store from './modules/store';
import cart from './modules/cart';
import product from './modules/product';
import certificate from './modules/certificate';

export default {
  auth,
  modal,
  table,
  chat,
  user,
  file,
  order,
  banner,
  catalog,
  store,
  cart,
  product,
  certificate,
}