import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-824965a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalover" }
const _hoisted_2 = { class: "modal common--block flex-column justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_modal_container = _resolveComponent("modal-container")!
  const _component_modal_actions = _resolveComponent("modal-actions")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: "overflow",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeModal && _ctx.removeModal(...args)))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_modal_header, { data: _ctx.data }, null, 8, ["data"]),
      _createVNode(_component_modal_container, { data: _ctx.data }, null, 8, ["data"]),
      _createVNode(_component_modal_actions, {
        onRemoveModal: _ctx.removeModal,
        onSubmit: _ctx.submit,
        data: _ctx.data,
        pending: _ctx.pendingRequest
      }, null, 8, ["onRemoveModal", "onSubmit", "data", "pending"])
    ])
  ]))
}