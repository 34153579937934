export enum STATUSES {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  SERVER = 500,
}

export enum ROLES {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  PARTNER = 'PARTNER',
  USER = 'USER',
}

export enum FILES_TYPES {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
};

export enum PRODUCT_TYPES {
  UNIT = 'UNIT',
  PACK = 'PACK'
}

export enum STATUS_TYPES {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  ASSEMBLED = 'ASSEMBLED',
  RECEIVED = 'RECEIVED',
  DELETED = 'DELETED',
  CREATED = 'CREATED',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED'
}