import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'PreloaderIcon',
  template: `
  <svg width="267" height="265" viewBox="0 0 267 265" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3406 179.904C36.839 186.919 49.2174 191.372 60.5927 192.226C66.2855 192.654 78.0281 190.641 82.3196 188.503C91.493 183.933 99.2826 174.693 102.396 164.689L103.023 162.673L99.7077 158.937C93.5967 152.052 85.9661 147.652 77.2375 145.98C74.0103 145.361 71.8774 145.388 66.3097 146.116C59.0129 147.069 55.1422 148.241 49.2329 151.286C44.0335 153.966 36.7324 159.378 32.3192 163.824C27.8883 168.288 22.3001 175.601 22.4858 176.693C22.5529 177.087 24.2878 178.531 26.3406 179.904Z" fill="#FF3147"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M110.221 225.795C116.338 221.146 121.354 214.908 124.101 208.533C125.475 205.343 126.645 198.188 126.266 195.296C125.456 189.112 121.684 182.799 116.546 179.025L115.511 178.264L112.705 179.431C107.532 181.581 103.488 185.099 100.795 189.789C99.7996 191.523 99.3922 192.755 98.707 196.103C97.8091 200.489 97.7158 202.949 98.2961 206.952C98.8067 210.474 100.473 215.747 102.156 219.168C103.845 222.602 106.945 227.266 107.609 227.376C107.85 227.416 109.025 226.704 110.221 225.795Z" fill="#FF3147"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M86.7385 26.3392C79.7228 36.8376 75.2697 49.216 74.4158 60.5913C73.9886 66.2841 76.0009 78.0267 78.139 82.3182C82.7089 91.4916 91.9496 99.2811 101.953 102.394L103.969 103.022L107.705 99.7063C114.59 93.5953 118.99 85.9647 120.663 77.2361C121.281 74.0089 121.254 71.876 120.527 66.3083C119.573 59.0115 118.401 55.1408 115.356 49.2315C112.677 44.0321 107.264 36.731 102.818 32.3178C98.3544 27.8869 91.0412 22.2987 89.9496 22.4844C89.5554 22.5515 88.1107 24.2864 86.7385 26.3392Z" fill="#FFD249"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M40.8475 110.221C45.4964 116.338 51.7347 121.354 58.1095 124.101C61.2999 125.475 68.4543 126.645 71.347 126.266C77.5304 125.456 83.8437 121.684 87.6179 116.546L88.3786 115.511L87.2118 112.704C85.0612 107.532 81.5437 103.488 76.8537 100.795C75.1197 99.7995 73.8874 99.3922 70.54 98.7069C66.1532 97.809 63.694 97.7158 59.6908 98.2961C56.1684 98.8066 50.8951 100.473 47.4748 102.156C44.0408 103.845 39.3761 106.945 39.2666 107.609C39.227 107.849 39.9386 109.025 40.8475 110.221Z" fill="#FFD249"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M240.301 98.9381C229.803 91.9224 217.424 87.4693 206.049 86.6154C200.356 86.1882 188.614 88.2005 184.322 90.3386C175.149 94.9086 167.359 104.149 164.246 114.153L163.618 116.169L166.934 119.904C173.045 126.79 180.676 131.19 189.404 132.862C192.632 133.481 194.764 133.454 200.332 132.726C207.629 131.772 211.5 130.601 217.409 127.555C222.608 124.876 229.909 119.464 234.323 115.018C238.754 110.554 244.342 103.241 244.156 102.149C244.089 101.755 242.354 100.31 240.301 98.9381Z" fill="#FF3147"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M156.421 53.0467C150.303 57.6956 145.288 63.9339 142.541 70.3087C141.167 73.4991 139.996 80.6535 140.376 83.5462C141.186 89.7296 144.958 96.0429 150.096 99.8171L151.131 100.578L153.937 99.411C159.11 97.2604 163.154 93.7429 165.847 89.0529C166.842 87.3189 167.25 86.0866 167.935 82.7393C168.833 78.3524 168.926 75.8933 168.346 71.89C167.835 68.3676 166.169 63.0943 164.486 59.674C162.797 56.24 159.697 51.5753 159.032 51.4658C158.792 51.4262 157.617 52.1378 156.421 53.0467Z" fill="#FF3147"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M169.705 238.501C176.721 228.002 181.174 215.624 182.028 204.249C182.455 198.556 180.443 186.813 178.305 182.522C173.735 173.348 164.494 165.559 154.49 162.446L152.474 161.818L148.739 165.134C141.854 171.245 137.453 178.875 135.781 187.604C135.163 190.831 135.19 192.964 135.917 198.532C136.871 205.828 138.043 209.699 141.088 215.608C143.767 220.808 149.18 228.109 153.625 232.522C158.089 236.953 165.402 242.541 166.494 242.355C166.888 242.288 168.333 240.553 169.705 238.501Z" fill="#FFD249"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M215.596 154.619C210.947 148.501 204.709 143.486 198.334 140.739C195.144 139.365 187.989 138.194 185.097 138.574C178.913 139.384 172.6 143.156 168.826 148.294L168.065 149.329L169.232 152.135C171.382 157.308 174.9 161.352 179.59 164.045C181.324 165.04 182.556 165.448 185.904 166.133C190.29 167.031 192.75 167.124 196.753 166.544C200.275 166.033 205.548 164.367 208.969 162.684C212.403 160.995 217.067 157.895 217.177 157.23C217.217 156.99 216.505 155.815 215.596 154.619Z" fill="#FFD249"/>
  </svg>
  `
});

export default script;