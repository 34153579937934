import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ChatIcon',
  template: `
  <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6813 0.334036C12.5394 0.358293 12.0981 0.430033 11.7007 0.493412C10.6085 0.66755 9.13426 1.16725 7.99757 1.7485C5.55655 2.99678 3.5216 5.03482 2.27807 7.47687C0.412728 11.1398 0.33469 15.3095 2.0611 19.0627L2.4647 19.9401L1.63964 22.9852L0.937923 25.5751C0.861628 25.8567 0.941799 26.1575 1.14809 26.3638C1.35438 26.5701 1.65526 26.6503 1.93685 26.574L4.52669 25.8723L7.57177 25.0472L8.44917 25.4508C13.5903 27.8156 19.4939 26.7385 23.4412 22.7156C25.2675 20.8543 26.423 18.684 26.9928 16.0453C27.2497 14.8555 27.2497 12.1219 26.9928 10.9321C26.4072 8.21998 25.1731 5.95743 23.2501 4.07072C21.3977 2.25316 19.1985 1.08106 16.6038 0.528405C15.8665 0.371299 13.2281 0.240619 12.6813 0.334036Z" fill="white"/>
  <circle cx="14" cy="13.4997" r="1.49186" fill="#FF3147"/>
  <circle cx="9.35057" cy="13.4997" r="1.49186" fill="#FF3147"/>
  <circle cx="18.6495" cy="13.4997" r="1.49186" fill="#FF3147"/>
  </svg>
  `
});

export default script;
