
import { defineComponent } from "vue-demi";
import { dynamicsObject } from "@/interfaces";
import { DownArrowIcon } from '@/plugins/icons';
import { MONTHS_NAMES } from "@/store/common/names";
import { switchMonth, getWeeks, dayWeekList, getDateNullTime } from '@/utils/calendar';
import dateFilter from '@/filters/date.filter';
 
export default defineComponent({
  emits: ['selectedDate'],
  name: 'CalendarMain',
  props: {
    date: {
      type: Date,
      required: true
    },
  },
  data() {
    return {
      dayweek: dayWeekList(),
      currentMonth: new Date(),
    }
  },
  computed: {
    monthFilter() {
      return `${MONTHS_NAMES[this.currentMonth.getMonth()]}, ${this.currentMonth.getFullYear()}`;
    },
  },
  methods: {
    isSeletedDate(date: Date) {
      return Number(date) === Number(getDateNullTime(this.date));
    },
    selectDate(date: dynamicsObject) {
      this.$emit('selectedDate', date.value);
    },
    selectMonth(inc: number) {
      this.currentMonth = switchMonth(this.currentMonth as Date, inc);
    },
    getWeeks,
    dateFilter
  },
  components: {
    DownArrowIcon
  }
})
