import { dynamicsObject } from "@/interfaces";
import axios, { AxiosRequestHeaders } from "axios";

export default {
  checkAuthenticationMethod: async () => {
    return await axios.get("/api/authentication");
  },
  registrationMethod: async (data: { email: string, name: string, password: string }) => {
    return await axios.post("/api/registration", data);
  },
  loginMethod: async (data: { email: string, password: string }) => {
    return await axios.post("/api/login", data);
  },
  resetMethod: async (data: dynamicsObject) => {
    return await axios.post("/api/auth/reset", data);
  },
  checkCodeMethod: async (data: dynamicsObject) => {
    return await axios.post("/api/auth/code", data);
  },
  changeMethod: async (data: dynamicsObject) => {
    return await axios.post("/api/auth/change", data);
  },
  logoutMethod: async () => {
    return await axios.post("/api/auth/logout");
  },
  socketConnection: async (data: dynamicsObject) => {
    return await axios.post('/api/auth/socket', data)
  }
};
