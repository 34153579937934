import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ClientIcon',
  template: `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1424_753)">
  <path d="M10 8.08594C11.8739 8.08594 13.3984 6.56141 13.3984 4.6875C13.3984 2.81359 11.8739 1.28906 10 1.28906C8.12609 1.28906 6.60156 2.81359 6.60156 4.6875C6.60156 6.56141 8.12609 8.08594 10 8.08594ZM10 2.46094C11.2277 2.46094 12.2266 3.45977 12.2266 4.6875C12.2266 5.91523 11.2277 6.91406 10 6.91406C8.77227 6.91406 7.77344 5.91523 7.77344 4.6875C7.77344 3.45977 8.77227 2.46094 10 2.46094Z" fill="#FFD249"/>
  <path d="M16.875 8.08594C18.0596 8.08594 19.0234 7.12215 19.0234 5.9375C19.0234 4.75285 18.0596 3.78906 16.875 3.78906C15.6904 3.78906 14.7266 4.75285 14.7266 5.9375C14.7266 7.12215 15.6904 8.08594 16.875 8.08594ZM16.875 4.96094C17.4135 4.96094 17.8516 5.39902 17.8516 5.9375C17.8516 6.47598 17.4135 6.91406 16.875 6.91406C16.3365 6.91406 15.8984 6.47598 15.8984 5.9375C15.8984 5.39902 16.3365 4.96094 16.875 4.96094Z" fill="#FFD249"/>
  <path d="M17.3477 9.41406H16.4414C15.3735 9.41406 14.4516 10.045 14.0315 10.9512C13.1666 10.0071 11.9243 9.41406 10.5461 9.41406H9.45398C8.0757 9.41406 6.8334 10.0071 5.96852 10.9512C5.5484 10.045 4.62652 9.41406 3.55859 9.41406H2.65234C1.18984 9.41406 0 10.5967 0 12.0504V16.3164C0 16.9475 0.515391 17.4609 1.14891 17.4609H4.73199C4.79207 18.1602 5.38004 18.7109 6.09461 18.7109H13.9054C14.62 18.7109 15.2079 18.1602 15.268 17.4609H18.8102C19.4663 17.4609 20 16.9293 20 16.2758V12.0504C20 10.5967 18.8102 9.41406 17.3477 9.41406ZM1.17188 12.0504C1.17188 11.2429 1.83602 10.5859 2.65234 10.5859H3.55859C4.37492 10.5859 5.03906 11.2429 5.03906 12.0504V12.4516C4.64504 13.4775 4.72656 14.0924 4.72656 16.2891H1.17188V12.0504ZM14.1016 17.3429C14.1016 17.4511 14.0136 17.5391 13.9054 17.5391H6.09457C5.98641 17.5391 5.89844 17.4511 5.89844 17.3429V14.1415C5.89844 12.1809 7.49344 10.5859 9.45395 10.5859H10.546C12.5066 10.5859 14.1016 12.1809 14.1016 14.1414V17.3429ZM18.8281 16.2758C18.8281 16.296 19.0466 16.2891 15.2734 16.2891C15.2734 14.0761 15.3543 13.4759 14.9609 12.4516V12.0504C14.9609 11.2429 15.6251 10.5859 16.4414 10.5859H17.3477C18.164 10.5859 18.8281 11.2429 18.8281 12.0504V16.2758Z" fill="#FFD249"/>
  <path d="M3.125 8.08594C4.30965 8.08594 5.27344 7.12215 5.27344 5.9375C5.27344 4.75285 4.30965 3.78906 3.125 3.78906C1.94035 3.78906 0.976562 4.75285 0.976562 5.9375C0.976562 7.12215 1.94035 8.08594 3.125 8.08594ZM3.125 4.96094C3.66348 4.96094 4.10156 5.39902 4.10156 5.9375C4.10156 6.47598 3.66348 6.91406 3.125 6.91406C2.58652 6.91406 2.14844 6.47598 2.14844 5.9375C2.14844 5.39902 2.58652 4.96094 3.125 4.96094Z" fill="#FFD249"/>
  </g>
  <defs>
  <clipPath id="clip0_1424_753">
  <rect width="20" height="20" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  `
});

export default script;