import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'EmptyImageIcon',
  props: ['color'],
  template: `
  <svg width="109" height="109" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M77.6257 0.152344C86.2687 0.152344 93.7238 3.06109 99.1794 8.56984C104.882 14.3223 108.011 22.6911 108 32.1378L108.008 70.0349C108.017 70.1807 108.019 70.3269 108.013 70.4728L108 76.2565C108 95.3828 95.7824 108.237 77.5988 108.237H31.1266C12.9591 108.237 0.752441 95.3828 0.752441 76.2565V32.1323C0.752441 13.0061 12.9591 0.152344 31.1266 0.152344H77.6257ZM79.1124 58.8273C75.9519 58.8273 72.2432 64.0977 68.959 68.7506C64.4548 75.1423 59.7947 81.756 52.7749 81.756C48.0288 81.756 43.6804 79.9144 39.848 78.284C33.7528 75.6948 30.99 74.8661 28.2864 77.1086C22.8743 81.603 17.5266 89.787 15.0694 93.8143C18.9478 97.8439 24.4571 100.112 31.1266 100.112H77.5988C91.1707 100.112 99.9374 90.7515 99.9374 76.2565L99.9373 71.9286L99.8134 71.7969C95.108 66.9115 86.9829 58.8273 79.1124 58.8273ZM77.6257 8.27734H31.1266C17.5762 8.27734 8.81494 17.6428 8.81494 32.1323V76.2565C8.81494 79.878 9.36184 83.179 10.3937 86.0935C13.5041 81.3608 18.2018 74.9532 23.164 70.8361C29.8935 65.2569 37.139 68.3227 42.9763 70.7981C46.4109 72.2552 49.6574 73.631 52.7749 73.631C55.6344 73.631 59.2195 68.5394 62.3908 64.049C66.7929 57.7873 71.7917 50.7023 79.1124 50.7023C87.1466 50.7023 94.4233 55.6778 99.9431 60.6156L99.9374 32.1323C99.9481 24.7494 97.7121 18.5853 93.4766 14.3115C89.5636 10.3628 84.0864 8.27734 77.6257 8.27734ZM37.6088 22.8455C45.3004 22.8509 51.5569 29.1559 51.5623 36.9017V36.9071C51.5623 44.6584 45.3058 50.9634 37.6088 50.9634C29.9118 50.9634 23.6553 44.6584 23.6553 36.9071C23.6553 29.1505 29.9118 22.8455 37.6088 22.8455ZM37.6034 30.9705C34.3569 30.9705 31.7178 33.6301 31.7178 36.9071C31.7178 40.1788 34.3569 42.8384 37.6088 42.8384C40.8553 42.8384 43.4998 40.1788 43.4998 36.9126C43.4944 33.6355 40.8499 30.9759 37.6034 30.9705Z" :fill="color || '#FFF6A0'"/>
  </svg>
  `
});

export default script;