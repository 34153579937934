import { getCurrentInstance } from 'vue';
import api from '../api';

export const useEmitter = () => {
  type Emits<EventType extends string | symbol, T> = {
    on(type: EventType, handler: (arg: T) => void): void;
    off(type: EventType, handler: (arg: T) => void): void;
    emit(type: EventType, arg: T): void;
  };
  type Emitter = Emits<string, any>;

  const internalInstance: any = getCurrentInstance();
  const { emitter }: { emitter: Emitter } = internalInstance.appContext.config.globalProperties;
  
  return emitter;
};

export const useAPI = () => {
  return api;
};

export const useJQ = () => {
  const internalInstance: any = getCurrentInstance();
  const { jq }: { jq: JQueryStatic } = internalInstance.appContext.config.globalProperties;

  return jq;
};

export const useSocket = () => {
  const internalInstance: any = getCurrentInstance();
  const { socket } = internalInstance.appContext.config.globalProperties;

  return socket;
};