import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'LinkIcon',
  template: `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34999 15.1058C3.16439 16.2914 3.16439 18.2137 4.34999 19.3993C5.53559 20.5849 7.45783 20.5849 8.64343 19.3993L12.6066 15.4361C12.8802 15.1625 13.3238 15.1625 13.5974 15.4361C13.871 15.7097 13.871 16.1533 13.5974 16.4269L9.63423 20.3901C7.90143 22.1229 5.092 22.1229 3.3592 20.3901C1.62639 18.6573 1.62639 15.8478 3.3592 14.115L7.32238 10.1519C7.59598 9.87825 8.03957 9.87825 8.31317 10.1519C8.58677 10.4255 8.58677 10.869 8.31317 11.1426L4.34999 15.1058Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2115 8.8308C20.3971 7.6452 20.3971 5.72296 19.2115 4.53736C18.0259 3.35176 16.1037 3.35176 14.9181 4.53736L10.9549 8.50054C10.6813 8.77414 10.2377 8.77414 9.96412 8.50054C9.69052 8.22694 9.69052 7.78334 9.96412 7.50974L13.9273 3.54657C15.6601 1.81376 18.4695 1.81376 20.2023 3.54657C21.9351 5.27937 21.9351 8.0888 20.2023 9.8216L16.2391 13.7848C15.9655 14.0584 15.522 14.0584 15.2484 13.7848C14.9748 13.5112 14.9748 13.0676 15.2484 12.794L19.2115 8.8308Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9827 15.7664C7.7091 15.4928 7.7091 15.0492 7.9827 14.7756L13.9275 8.83083C14.2011 8.55723 14.6447 8.55723 14.9183 8.83083C15.1919 9.10443 15.1919 9.54802 14.9183 9.82162L8.9735 15.7664C8.6999 16.04 8.2563 16.04 7.9827 15.7664Z" fill="white"/>
  </svg>  
  `
});

export default script;

