import { Modal } from "@/interfaces/modal/modal.dto";

interface State {
  modal: Modal.Main | null
}

export default {
  state: {
    modal: null
  },
  mutations: {
    createModal(state: State, modal: Modal.Main) {
      state.modal = modal;
    },
    destroyModal(state: State) {
      state.modal = null;
    },
  },
  getters: {
    modal: (state: State) => state.modal
  }
}