import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'DownArrowicon',
  props: ['color'],
  template: `
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.999878 1L8.99988 9L16.9999 1" :stroke="color || 'black'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `
});

export default script;