import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'AnalyticsIcon',
  props: ['color'],
  template: `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_1424_777)">
  <path d="M19.5833 19.1667H0.41668C0.18668 19.1667 0 19.3534 0 19.5834C0 19.8134 0.18668 20.0001 0.41668 20.0001H19.5834C19.8134 20.0001 20 19.8134 20 19.5834C20 19.3534 19.8133 19.1667 19.5833 19.1667Z" :fill="color || '#FFD249'"/>
  <path d="M3.74993 14.1667H1.24993C1.01993 14.1667 0.833252 14.3534 0.833252 14.5834V19.5834C0.833252 19.8134 1.01993 20.0001 1.24993 20.0001H3.74993C3.97993 20.0001 4.16661 19.8134 4.16661 19.5834V14.5834C4.16661 14.3534 3.97993 14.1667 3.74993 14.1667ZM3.33325 19.1667H1.66661V15.0001H3.33329V19.1667H3.33325Z" :fill="color || '#FFD249'"/>
  <path d="M8.74993 10H6.24993C6.01993 10 5.83325 10.1867 5.83325 10.4167V19.5834C5.83325 19.8134 6.01993 20 6.24993 20H8.74993C8.97993 20 9.16661 19.8134 9.16661 19.5834V10.4167C9.16661 10.1867 8.97993 10 8.74993 10ZM8.33325 19.1667H6.66657V10.8333H8.33325V19.1667Z" :fill="color || '#FFD249'"/>
  <path d="M13.7499 11.6667H11.2499C11.0199 11.6667 10.8333 11.8534 10.8333 12.0834V19.5834C10.8333 19.8134 11.0199 20.0001 11.2499 20.0001H13.7499C13.9799 20.0001 14.1666 19.8134 14.1666 19.5834V12.0834C14.1666 11.8534 13.9799 11.6667 13.7499 11.6667ZM13.3333 19.1667H11.6666V12.5001H13.3333V19.1667Z" :fill="color || '#FFD249'"/>
  <path d="M18.7499 6.66675H16.2499C16.0199 6.66675 15.8333 6.85343 15.8333 7.08343V19.5834C15.8333 19.8134 16.0199 20.0001 16.2499 20.0001H18.7499C18.9799 20.0001 19.1666 19.8134 19.1666 19.5834V7.08343C19.1666 6.85339 18.9799 6.66675 18.7499 6.66675ZM18.3333 19.1667H16.6666V7.50007H18.3333V19.1667Z" :fill="color || '#FFD249'"/>
  <path d="M2.49993 7.5C1.58075 7.5 0.833252 8.2475 0.833252 9.16668C0.833252 10.0859 1.58075 10.8334 2.49993 10.8334C3.41911 10.8334 4.16661 10.0859 4.16661 9.16668C4.16661 8.2475 3.41911 7.5 2.49993 7.5ZM2.49993 10C2.03993 10 1.66661 9.62582 1.66661 9.16668C1.66661 8.7075 2.03993 8.33336 2.49993 8.33336C2.95993 8.33336 3.33325 8.70754 3.33325 9.16668C3.33325 9.62582 2.95993 10 2.49993 10Z" :fill="color || '#FFD249'"/>
  <path d="M7.49993 3.33325C6.58075 3.33325 5.83325 4.08075 5.83325 4.99993C5.83325 5.91911 6.58075 6.6666 7.49993 6.6666C8.41911 6.6666 9.16661 5.91911 9.16661 4.99993C9.16661 4.08075 8.41911 3.33325 7.49993 3.33325ZM7.49993 5.83325C7.03993 5.83325 6.66661 5.45907 6.66661 4.99993C6.66661 4.54075 7.03993 4.16661 7.49993 4.16661C7.95993 4.16661 8.33325 4.54079 8.33325 4.99993C8.33325 5.45911 7.95993 5.83325 7.49993 5.83325Z" :fill="color || '#FFD249'"/>
  <path d="M12.4999 5C11.5808 5 10.8333 5.7475 10.8333 6.66668C10.8333 7.58586 11.5808 8.33336 12.4999 8.33336C13.4191 8.33336 14.1666 7.58586 14.1666 6.66668C14.1666 5.7475 13.4191 5 12.4999 5ZM12.4999 7.5C12.0399 7.5 11.6666 7.12582 11.6666 6.66668C11.6666 6.2075 12.0399 5.83336 12.4999 5.83336C12.9599 5.83336 13.3333 6.20754 13.3333 6.66668C13.3333 7.12582 12.9599 7.5 12.4999 7.5Z" :fill="color || '#FFD249'"/>
  <path d="M17.4999 0C16.5808 0 15.8333 0.7475 15.8333 1.66668C15.8333 2.58586 16.5808 3.33336 17.4999 3.33336C18.4191 3.33336 19.1666 2.58586 19.1666 1.66668C19.1666 0.7475 18.4191 0 17.4999 0ZM17.4999 2.5C17.0399 2.5 16.6666 2.12582 16.6666 1.66668C16.6666 1.2075 17.0399 0.833359 17.4999 0.833359C17.9599 0.833359 18.3333 1.20754 18.3333 1.66668C18.3333 2.12582 17.9599 2.5 17.4999 2.5Z" :fill="color || '#FFD249'"/>
  <path d="M16.9119 2.25493C16.7494 2.09243 16.4852 2.09243 16.3227 2.25493L13.0894 5.48825C12.9269 5.65075 12.9269 5.91493 13.0894 6.07743C13.1711 6.15825 13.2777 6.19911 13.3844 6.19911C13.4902 6.19911 13.5969 6.15911 13.6786 6.07743L16.9119 2.84411C17.0744 2.68161 17.0744 2.41743 16.9119 2.25493Z" :fill="color || '#FFD249'"/>
  <path d="M11.4924 5.70489L8.81575 4.94157C8.59157 4.87657 8.36407 5.00657 8.30157 5.22825C8.23825 5.44993 8.36657 5.67993 8.58739 5.74325L11.2641 6.50657C11.3024 6.51739 11.3407 6.52239 11.3782 6.52239C11.5599 6.52239 11.7266 6.40321 11.7791 6.21989C11.8424 5.99907 11.7141 5.76821 11.4924 5.70489Z" :fill="color || '#FFD249'"/>
  <path d="M6.85012 5.51924C6.7068 5.33924 6.44512 5.31007 6.26512 5.45257L3.12343 7.95757C2.94343 8.10175 2.91343 8.36339 3.05761 8.54339C3.14011 8.64671 3.26179 8.70007 3.38343 8.70007C3.47425 8.70007 3.56593 8.67007 3.64261 8.61007L6.7843 6.10506C6.9643 5.96089 6.9943 5.69924 6.85012 5.51924Z" :fill="color || '#FFD249'"/>
  </g>
  <defs>
  <clipPath id="clip0_1424_777">
  <rect width="20" height="20" fill="white"/>
  </clipPath>
  </defs>
  </svg>
  `
});

export default script;