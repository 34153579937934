export const CDEKWidgetProps = (city?: string) => ({
  //showWarns: true,
  //showErrors: true,
  debug: true,
  apiKey: '2aa30ec3-20e3-4341-8717-30d72ad319d0',
  servicePath: 'https://service.pokrovpryanik.ru/service.php',
  //hideMessages: false,
  canChoose: true,
  //popup: true,
  //country: 'Россия',
  defaultLocation: city || 'Москва',
  from: 'Покров',
  root: 'forpvz',
  bymapcoord: false,
  hideFilters: {
    have_cash: true,
    is_dressing_room: false,
  },
  hideDeliveryOptions: {
    office: false,
    door: false,
  }
  //detailAddress: true,
});

// длина 25 см, ширина 17 см, высота 7 см, вес 6 килограмм.

interface PVZ {
  Address: string,
  Cash: boolean,
  Metro: string,
  Postamat: boolean,
  Phone: string,
  Name: string,
  Station: string,
  WorkTime: string,
}

export interface CDEKInfo {
  PVZ: PVZ,
  city: string,
  cityName: string,
  currency: string,
  id: string,
  price: string,
  tarif: number,
  term: string
}