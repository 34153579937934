import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  getListMethod: async () => {
    return await axios.get("/api/chat/list");
  },
  getMethod: async () => {
    return await axios.get("/api/chat");
  },
  createMessageMethod: async (data: dynamicsObject) => {
    return await axios.post("/api/chat/message", data);
  },
  deleteMethod: async (_id: string) => {
    return await axios.delete("/api/chat/" + _id);
  },
};
