import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'BannerIcon',
  template: `
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1H6C5.44772 1 5 1.44772 5 2V16C5 16.5523 5.44772 17 6 17H12C12.5523 17 13 16.5523 13 16V2C13 1.44772 12.5523 1 12 1ZM6 0C4.89543 0 4 0.89543 4 2V16C4 17.1046 4.89543 18 6 18H12C13.1046 18 14 17.1046 14 16V2C14 0.895431 13.1046 0 12 0H6Z" fill="#FFD249"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4 3H2C1.44772 3 1 3.44772 1 4V14C1 14.5523 1.44772 15 2 15H4V3ZM2 2C0.895431 2 0 2.89543 0 4V14C0 15.1046 0.89543 16 2 16H5V2H2Z" fill="#FFD249"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 3H16C16.5523 3 17 3.44772 17 4V14C17 14.5523 16.5523 15 16 15H14V3ZM16 2C17.1046 2 18 2.89543 18 4V14C18 15.1046 17.1046 16 16 16H13V2H16Z" fill="#FFD249"/>
  </svg>
  `
});

export default script;