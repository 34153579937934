import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  requestMethod: async (data: dynamicsObject) => {
    return await axios.get("/api/authentication");
  },
  getProductListMethod: async (params: dynamicsObject) => {
    return await axios.get("/api/product/list", {
      params
    });
  },
  getCategoryAllListMethod: async () => {
    return await axios.get("/api/category/list/all");
  },
};
