import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const common = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'feedback',
      data: {
        email: data?.email,
        name: data?.name,
        content: '',
      },
      inputs: [
        { required: true, name: 'ФИО *', placeholder: 'Введите ФИО', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Email *', placeholder: 'Введите Email', id: 'email', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Текст *', placeholder: 'Напишите нам...', id: 'content', grid: '1 / 11', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
  ]
}