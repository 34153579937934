import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  requestMethod: async (data: dynamicsObject) => {
    return await axios.get("/api/authentication");
  },
  getProductAllListMethod: async () => {
    return await axios.get("/api/product/list/all");
  },
  getStoreAllListMethod: async () => {
    return await axios.get("/api/store/list/all");
  },
};
