import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'StarIcon',
  template: `
  <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M29.8944 0.625562C31.9623 -0.755226 34.7158 0.834542 34.554 3.31577L33.9068 13.2366C33.8425 14.2219 34.2672 15.1757 35.0424 15.7872L42.8481 21.9446C44.8003 23.4846 44.1392 26.5946 41.7294 27.2074L32.0942 29.6576C31.1373 29.901 30.3613 30.5996 30.0193 31.5259L26.5754 40.8522C25.714 43.1848 22.5519 43.5171 21.2244 41.4146L15.9167 33.0081C15.3896 32.1732 14.4853 31.6512 13.4987 31.6121L3.56462 31.2187C1.08006 31.1203 -0.213167 28.2157 1.37621 26.3035L7.73107 18.6578C8.36221 17.8984 8.5793 16.8771 8.31157 15.9267L5.6159 6.35729C4.9417 3.96393 7.30456 1.83641 9.61433 2.75709L18.8496 6.43827C19.7668 6.80387 20.8052 6.69473 21.6263 6.14642L29.8944 0.625562Z" fill="#FFD249"/>
  </svg>
  `
});

export default script;
