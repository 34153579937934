import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'ScrollDownIcon',
  template: `
  <svg width="16" height="45" viewBox="0 0 16 45" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.29277 44.7081C7.6833 45.0986 8.31646 45.0986 8.70699 44.7081L15.0709 38.3441C15.4615 37.9536 15.4615 37.3204 15.0709 36.9299C14.6804 36.5394 14.0473 36.5394 13.6567 36.9299L7.99988 42.5868L2.34303 36.9299C1.9525 36.5394 1.31934 36.5394 0.928812 36.9299C0.538287 37.3204 0.538287 37.9536 0.928812 38.3441L7.29277 44.7081ZM6.99988 0.000976606L6.99988 44.001L8.99988 44.001L8.99988 0.000976519L6.99988 0.000976606Z" fill="#1D1D1D"/>
  </svg>  
  `
});

export default script;