import VueTheMask from "vue-the-mask";
import axios from "axios";
import jquery from "jquery";
import { createApp } from "vue";
import mitt from "mitt";
import io from "socket.io-client";
import ElementPlus from "element-plus";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyLoad from 'vue3-lazyload';

import api from "./api";
import "./helpers/interceptors";
import "./helpers/online";
import "./utils/math";

import "element-plus/dist/index.css";

if (!process.env.VUE_APP_SOCKET_DEV_URL)
  throw new Error("Заполните VUE_APP_SOCKET_DEV_URL!");

const socket = io(process.env.VUE_APP_SOCKET_DEV_URL, {
  transports: ["websocket", "polling"],
});

socket.on("disconnect", () => {
  socket.connect();
});

const app = createApp(App);

app.config.globalProperties.socket = socket;
app.config.globalProperties.axios = axios;
app.config.globalProperties.jq = jquery;
app.config.globalProperties.emitter = mitt();
app.config.globalProperties.API = api;
Object.typedKeys = Object.keys as any;

app
  .use(VueLazyLoad)
  .use(VueTheMask.default)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .mount("#app");
