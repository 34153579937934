
import { defineComponent, PropType } from "vue-demi";
import { Product } from "@/interfaces/product/product.dto";
import { PRODUCT_TYPES } from "@/utils/enums";

export default defineComponent({
  name: "DeliveryInfoProduct",
  props: {
    product: {
      type: Object as PropType<Product.Dto>,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentPrice() {
      return this.product.type === PRODUCT_TYPES.UNIT ? this.product.pricePerUnit : this.product.pricePerPack;
    },
    currentLength() {
      return this.product.type === PRODUCT_TYPES.UNIT ? 1 : this.product.unitsPerPack;
    }
  },
});
