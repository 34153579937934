import { dynamicsObject } from "@/interfaces";
import { useAPI } from "@/use";
import { Commit } from "vuex";

interface State {
  drop: { options: dynamicsObject, target: string } | null,
  calendar: { options: dynamicsObject, date: Date } | null,
  delivery: string | null,
  productQuantity: number,
  chat: boolean
}

export default {
  state: {
    drop: null,
    calendar: null,
    delivery: null,
    productQuantity: 0,
    chat: false,
  },
  mutations: {
    createDropElement(state: State, drop: State['drop']) {
      if (state.drop) return state.drop = null;
      state.drop = drop;
    },
    destroyDropElement(state: State) {
      state.drop = null;
    },
    createCalendar(state: State, calendar: State['calendar']) {
      if (state.calendar) return state.calendar = null;
      state.calendar = calendar;
    },
    destroyCalendar(state: State) {
      state.calendar = null;
    },
    createDelivery(state: State, cart: State['delivery']) {
      state.delivery = cart;
    },
    destroyDelivery(state: State) {
      state.delivery = null;
    },
    setProductLength(state: State, length: number) {
      state.productQuantity = length;
    },
    createChat(state: State) {
      state.chat = !state.chat;
    },
    destroyChat(state: State) {
      state.chat = false;
    }
  },
  actions: {
    async updateProductCartQuantity({ commit }: { commit: Commit }) {
      const { data } = await useAPI().cart.getProductQuantityMethod();
      commit('setProductLength', data.quantity);
    } 
  },
  getters: {
    chat: (state: State) => state.chat,
    drop: (state: State) => state.drop,
    calendar: (state: State) => state.calendar,
    delivery: (state: State) => state.delivery,
    productQuantity: (state: State) => state.productQuantity,
  }
}