import { Modal } from "@/interfaces/modal/modal.dto";

export const store: Array<Modal.Content> = [
  {
    id: 'admin-store',
    title: 'Добавить склад',
    action: 'add',
    request: '/api/store',
    emit: 'admin-store',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Добавить'
  },
  {
    id: 'admin-store-edit',
    title: 'Редактировать категорию',
    action: 'edit',
    request: '/api/store',
    method: 'patch',
    cancelName: 'Отмена',
    emit: 'admin-store-edit',
    submitName: 'Сохранить'
  },
  {
    id: 'admin-store-delete',
    title: 'Удалить?',
    action: 'delete',
    request: '/api/store',
    method: 'delete',
    message: 'удалить склад',
    additionalMessage: 'Все товары с этого склада перейдут на склад "Удаленные товары"',
    emit: 'admin-store-delete',
    cancelName: 'Нет',
    submitName: 'Да'
  },
  {
    id: 'admin-store-product',
    title: 'Добавить товар на склад',
    action: 'add',
    request: '/api/store/product',
    emit: 'admin-store-product',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Добавить'
  },
  {
    id: 'admin-store-product-edit',
    title: 'Редактировать товар на складе',
    action: 'edit',
    request: '/api/store/product',
    emit: 'admin-store-product-edit',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить'
  },
  {
    id: 'admin-store-product-delete',
    title: 'Удалить?',
    action: 'delete',
    request: '/api/store/product',
    method: 'delete',
    message: 'удалить со склада товар',
    emit: 'admin-store-product-delete',
    cancelName: 'Нет',
    submitName: 'Да'
  }
];