import { Modal } from "@/interfaces/modal/modal.dto";

export const common: Array<Modal.Content> = [
  {
    id: 'feedback',
    title: 'Обратная связь',
    action: 'add',
    request: '/api/feedback',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Отправить'
  },
];