import axios from 'axios';
import { Commit } from 'vuex';
import { User } from '@/interfaces/user/user.dto';

import router from '@/router/index';
import roleRoute from "@/utils/roleRoute";
import { dynamicsObject } from '@/interfaces';
import { File } from '@/interfaces/file/file.dto';
import store from '@/store';

interface State {
  Authorization: string | null,
  user: User.Dto | null
}

export default {
  state: {
    Authorization: null,
    user: null,
  },
  getters: {
    Authorization: (state: State) => state.Authorization,
    user: (state: State) => state.user,
  },
  mutations: {
    updateUserDataModel(state: dynamicsObject, user: dynamicsObject) {
      if (!state.user) state.user = null;
      for (const key in user) {
        state.user[key] = user[key];
      }
      state.Authorization = user.accessToken;
    },
    updateUserAuthorization(state: State, Authorization: string) {
      state.Authorization = Authorization;
    },
    updateUserData(state: State, user: User.Dto) {
      state.Authorization = user.accessToken;
      state.user = user;
    },
    updatePartnerTest(state: State, test: string) {
      if (!state.user) return;
      (state.user as User.Dto).test = test;
    },
    updateUserAvatar(state: State, avatar: File.Dto) {
      if (!state.user) return;
      state.user.avatar = avatar;
    },
    logout(state: State) {
      state.Authorization = null;
      state.user = null;
    },
  },
  actions: {
    loginAction({ commit }: { commit: Commit }, user: User.Dto) {
      commit('updateUserData', user);
      const route = roleRoute(user);
      if (!route) return router.push('/auth');
      router.push(route);
    },
    logoutAction({ commit }: { commit: Commit }) {
      commit('logout');
      localStorage.removeItem('Authorization');
      localStorage.removeItem('user');
      router.push('/auth');
    },
    async checkAuthentication({ commit }: { commit: Commit }) {
      axios
        .get(`/api/authentication`)
        .then((res) => {
          commit('updateUserData', res.data);
          if (router.currentRoute.value.name === "Auth")
            router.push('/')
        })
        .catch(() => { 
          if (store.getters.user)
            store.dispatch('logoutAction');
        });
    },
  }
}