import { dynamicsObject } from "@/interfaces";

export const dayWeekList = () => {
  return ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
}

export const switchMonth = (month: Date, inc: number) => {
  month.setMonth(month.getMonth() + inc)
  return new Date(Number(month));
}

export const aroundDates = (date: Date) => {
  return getWeeks(date, true);
}

export const getMonthDates = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDate = new Date(year, month);
  let array: Array<Date> = [];

  const lastDate = new Date(year, month + 1, 0);
  for (let i = 0; i < lastDate.getDate(); i++) {
    array = [...array, new Date(firstDate.setDate(firstDate.getDate() + (!i ? 0 : 1)))];
  }
  return array;
}

export const getWeeks = (date: Date, around = false) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  
  let array: Array<dynamicsObject> = [];
  let firstDate = new Date(year, month);
  const firstLength = firstDate.getDay() || 7;
  
  for (let i = 1; i < firstLength; i++) {
    array = [{ value: new Date(firstDate.setDate(firstDate.getDate() + -1)), type: 'prev' }, ...array];
  }
  firstDate = new Date(year, month);
  const lastDate = new Date(year, month + 1, 0);
  for (let i = 0; i < lastDate.getDate(); i++) {
    array = [...array, { value: new Date(firstDate.setDate(firstDate.getDate() + (!i ? 0 : 1))), type: 'current' }];
  }
  const length = array.length;
  const max = (length > 35 ? 42 : 35);
  
  for (let i = 0; i < max - length; i++) {
    array = [...array, { value: new Date(firstDate.setDate(firstDate.getDate() + 1)), type: 'next' }];
  }
  if (around) return { startedAt: array.shift()?.value as Date, endedAt: array.pop()?.value as Date };
  return divisorWeek(array);
}

export function divisorWeek(array: Array<dynamicsObject>) {
  const result: Array<Array<dynamicsObject>> = [[], [], [], [], []];
  let week = 0;
  for (let i = 1; i <= array.length; i++) {
    if (!result[week]) result[week] = [];
    result[week] = [...result[week], array[i - 1]];
    if (i % 7 === 0) week += 1;
  }
  return result;
}

export function getDateNullTime(date: Date) {
  const d = new Date(date);
  return new Date(`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} 00:00`);
}

export const differenceTime = (first: Date, second: Date) => (new Date(first).getTime() - second.getTime()) / (1000 * 3600 * 24);

export const getTargetPosition = (target: JQuery<HTMLElement>) => {
  return { top: Number(target.offset()?.top) + Number(target.innerHeight()), left: Number(target?.offset()?.left) };
}