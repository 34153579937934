import { defineComponent } from 'vue-demi';

const script = defineComponent({
  name: 'OrderIcon',
  template: `
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.9194 16.4929L17.0262 5.23836C16.9705 4.51328 16.3573 3.94527 15.6301 3.94527H13.866V3.86598C13.866 1.73426 12.1317 0 9.99999 0C7.86827 0 6.13401 1.73426 6.13401 3.86598V3.94527H4.36987C3.64264 3.94527 3.0294 4.51324 2.97393 5.23684L2.0805 16.4944C2.01135 17.3958 2.32405 18.2932 2.93835 18.9564C3.55264 19.6196 4.42346 20 5.32749 20H14.6724C15.5764 20 16.4473 19.6196 17.0616 18.9564C17.6759 18.2931 17.9886 17.3958 17.9194 16.4929ZM7.3055 3.86598C7.3055 2.38023 8.51428 1.17148 9.99999 1.17148C11.4857 1.17148 12.6945 2.38027 12.6945 3.86598V3.94527H7.3055V3.86598ZM16.2021 18.1604C15.803 18.5912 15.2598 18.8285 14.6724 18.8285H5.32753C4.74018 18.8285 4.19698 18.5912 3.79784 18.1604C3.39874 17.7295 3.20366 17.1696 3.2485 16.5855L4.14186 5.32793C4.15092 5.20949 4.25108 5.11676 4.36987 5.11676H6.13401V6.55953C6.13401 6.88301 6.39628 7.14527 6.71975 7.14527C7.04323 7.14527 7.3055 6.88301 7.3055 6.55953V5.11676H12.6945V6.55953C12.6945 6.88301 12.9567 7.14527 13.2802 7.14527C13.6037 7.14527 13.866 6.88301 13.866 6.55953V5.11676H15.6301C15.7489 5.11676 15.849 5.20953 15.8583 5.32949L16.7514 16.584C16.7964 17.1696 16.6012 17.7294 16.2021 18.1604Z" fill="#FFD249"/>
  <path d="M12.6388 9.93304C12.4102 9.70429 12.0393 9.70429 11.8105 9.93304L9.19508 12.5485L8.18937 11.5428C7.96066 11.3141 7.58977 11.3141 7.36102 11.5428C7.13227 11.7716 7.13227 12.1424 7.36102 12.3712L8.7809 13.791C8.89527 13.9054 9.0452 13.9626 9.19504 13.9626C9.34488 13.9626 9.49484 13.9055 9.60918 13.791L12.6388 10.7614C12.8676 10.5326 12.8676 10.1618 12.6388 9.93304Z" fill="#FFD249"/>
  </svg>
  `
});

export default script;