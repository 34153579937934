import { dynamicsObject } from "@/interfaces";
import { Delivery } from "@/interfaces/delivery/delivery.dto";
import axios from "axios";

export default {
  getMethod: async () => {
    return await axios.get("/api/cart");
  },
  getProductQuantityMethod: async () => {
    return await axios.get("/api/cart/product/quantity");
  },
  updateProductQuantityMethod: async (_id: string, quantity: number) => {
    return await axios.patch("/api/cart/product/quantity", { _id, quantity });
  },
  updateCommentMethod: async (data: dynamicsObject) => {
    return await axios.patch("/api/cart/comment", data);
  },
  toggleProductMethod: async (_id: string, inc: number) => {
    return await axios.patch("/api/cart/product", { _id, inc });
  },
  toggleSelectProductMethod: async (_id: string, selected: boolean) => {
    return await axios.patch("/api/cart/product/selection", { _id, selected });
  },
  applyPromocodeMethod: async (promocode: string) => {
    return await axios.patch("/api/cart/promocode", { promocode });
  },
  applyCertificateMethod: async (certificate: string) => {
    return await axios.patch("/api/cart/certificate", { certificate });
  },
  applyDeliveryMethod: async (delivery: Delivery.Dto) => {
    return await axios.patch("/api/cart/delivery", delivery);
  },
  deleteProductCart: async (product: string) => {
    return await axios.delete("/api/cart/product/" + product);
  },
  deleteCart: async () => {
    return await axios.delete("/api/cart");
  },
};
