import axios from "axios";
import { dynamicsObject } from '@/interfaces';
import { Delivery } from '@/interfaces/delivery/delivery.dto';

export default {
  getMethod: async (order_id: string) => {
    return await axios.get("/api/order/" + order_id);
  },
  getDiagramMethod: async () => {
    return await axios.get("/api/order/diagram");
  },
  createMethod: async (data: dynamicsObject) => {
    return await axios.post("/api/order", data);
  },
  calculateMethod: async (info: Delivery.Dto) => {
    return await axios.post("/api/order/calculate", info);
  },
};
