import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28c9eafe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deliveryover" }
const _hoisted_2 = {
  key: 0,
  class: "delivery flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delivery_cdek = _resolveComponent("delivery-cdek")!
  const _component_delivery_info = _resolveComponent("delivery-info")!
  const _component_preloader = _resolveComponent("preloader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "overflow",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeDelivery && _ctx.removeDelivery(...args)))
    }),
    (_ctx.cart)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_delivery_cdek, {
            cart: _ctx.cart,
            onSelectedDelivery: _ctx.selectedDelivery
          }, null, 8, ["cart", "onSelectedDelivery"]),
          _createVNode(_component_delivery_info, {
            cart: _ctx.cart,
            info: _ctx.info,
            onContinueDelivery: _ctx.continueDelivery,
            onRemoveDelivery: _ctx.removeDelivery
          }, null, 8, ["cart", "info", "onContinueDelivery", "onRemoveDelivery"])
        ]))
      : (_openBlock(), _createBlock(_component_preloader, { key: 1 }))
  ]))
}