
import { defineComponent, PropType } from "vue-demi";
import { Cart } from "@/interfaces/cart/cart.dto";
import { PRODUCT_TYPES } from "@/utils/enums";
import Product from './Product.vue';

export default defineComponent({
  emits: ['removeDelivery', 'continueDelivery'],
  name: "DeliveryInfo",
  props: {
    cart: {
      type: Object as PropType<Cart.Dto>,
      required: true,
    },
    info: {
      type: Object,
    }
  },
  computed: {
    discount() {
      return this.cart.promocode ? this.total * 0.1 : 0;
    },
    certificate() {
      return this.cart.certificate ? this.cart.certificate.price : 0;
    },
    amount() {
      return this.total - this.discount - this.certificate;
    },
    amountWithDelivery() {
      return this.amount + Number(this.info?.price);
    },
    total() {
      return this.cart.products.filter(p => p.selected).reduce((acc, p) => {
        const amount = p.product.type === PRODUCT_TYPES.UNIT ? p.product.pricePerUnit : p.product.pricePerPack;
        return acc + (amount * p.quantity);
      }, 0);
    },
    totalWeight() {
      const weight = this.cart.products.reduce((acc, p) => {
        const weight = p.product.type === PRODUCT_TYPES.UNIT ? p.product.weight : p.product.weight * p.product.unitsPerPack;
        return acc + weight * p.quantity;
      }, 0);
      return weight >= 1000 ? `${weight / 1000} кг` : `${weight} г`;
    },
    currentDeliveryType() {
      return this.info?.type === 'office' ? 'Самовывоз из пункта выдачи' : 'Доставка по указанному адресу'
    }
  },
  components: {
    Product
  }
});
