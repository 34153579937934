import api from '@/api';
import { dynamicsObject } from '@/interfaces';
import { Modal } from '@/interfaces/modal/modal.dto';
import { PRODUCT_TYPE_DROP } from '@/store/common/catalog';

export default (modal: Modal.Main, options: dynamicsObject, datas: dynamicsObject, API: typeof api) => {
  const isCatalogRequest = modal.content.id === 'admin-catalog' || modal.content.id === 'admin-catalog-edit';
  const isStoreProductRequest = modal.content.id === 'admin-store-product' || modal.content.id === 'admin-store-product-edit';
  
  if (isCatalogRequest) {
    getCategoryList(API, modal);
    getTypeDatas(modal);
  }
  if (isStoreProductRequest) {
    getProductList(API, modal);
    getStoreList(API, modal);
  }
  // if (isLeadRequest) getLeadDatas(modal, datas);
};

async function getCategoryList(API: typeof api, modal: Modal.Main) {
  const result = await API.catalog.getCategoryAllListMethod();
  const input = modal.inputs.inputs.find(i => i.id === 'category');
  if (input) input.drop_data = result.data;
}

async function getProductList(API: typeof api, modal: Modal.Main) {
  const result = await API.store.getProductAllListMethod();
  const input = modal.inputs.inputs.find(i => i.id === 'product');
  if (input) input.drop_data = result.data;
}

async function getStoreList(API: typeof api, modal: Modal.Main) {
  const result = await API.store.getStoreAllListMethod();
  const input = modal.inputs.inputs.find(i => i.id === 'store');
  if (input) input.drop_data = result.data;
}
function getTypeDatas(modal: Modal.Main) {
  const types = modal.inputs.inputs.find(i => i.id === 'type');
  if (types) {
    types.drop_data = PRODUCT_TYPE_DROP;
    next(modal, 'type', PRODUCT_TYPE_DROP);
  }
  modal.inputs.data.sentedAt = new Date(modal.inputs.data.sentedAt);
}

function next(modal: Modal.Main, variable: string, array: Array<dynamicsObject>) {
  const element = array.find(e => e._id === modal.inputs.data[variable]);
  if (element) {
    modal.inputs.data[variable] = element.name;
    modal.inputs.data[variable + '_id'] = element._id;
  }
}