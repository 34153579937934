import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfa3915e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar--section-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_main = _resolveComponent("calendar-main")!

  return (_openBlock(), _createElementBlock("section", {
    class: "calendar--section als",
    ref: "calendar",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_calendar_main, {
        date: _ctx.calendar.date,
        onSelectedDate: _ctx.selectedDate
      }, null, 8, ["date", "onSelectedDate"])
    ])
  ], 4))
}