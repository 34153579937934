
import { defineComponent } from "vue-demi";
import { Cart } from "@/interfaces/cart/cart.dto";
import { Delivery } from "@/interfaces/delivery/delivery.dto";
import { useAPI } from "@/use";

import DeliveryCdek from './Cdek.vue';
import DeliveryInfo from './Info.vue';
import Preloader from '@/components/common/preloader/index.vue';

export default defineComponent({
  name: 'CommonDelivery',
  data() {
    return {
      cart: null as Cart.Dto | null,
      info: null as Delivery.Dto | null,
    }
  },
  created() {
    this.getCartMethod();
  },
  mounted() {
    document.addEventListener('keydown', this.keys);
  },
  unmounted() {
    document.removeEventListener('keydown', this.keys);
  },
  methods: {
    async getCartMethod() {
      const result = await useAPI().cart.getMethod();
      result.data.products = result.data.products.filter((p: { selected: boolean }) => p.selected);
      this.cart = result.data;
      this.info = result.data.delivery;
    },
    async continueDelivery() {
      const result = await useAPI().cart.applyDeliveryMethod(this.info as Delivery.Dto);
      this.emitter.emit('continueCart', result.data);
      this.removeDelivery();
    },
    async selectedDelivery(info: Delivery.Dto) {
      //const result = await useAPI().order.calculateMethod(info);
      this.info = info;
      //this.info.price = result.data.delivery;
      //this.info.term = result.data.term;
      //this.info.tarif = result.data.tarif;
    },
    removeDelivery() {
      this.$store.commit('destroyDelivery');
    },
    keys(ev: KeyboardEvent) {
      if (ev.key === 'Escape') this.removeDelivery();
    },
  },
  components: {
    DeliveryCdek,
    DeliveryInfo,
    Preloader
  }
})
