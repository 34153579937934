import { Modal } from "@/interfaces/modal/modal.dto";

export const staff: Array<Modal.Content> = [
  {
    id: 'admin-staff',
    title: 'Добавить сотрудника',
    action: 'add',
    request: '/api/user/staff',
    fileRequest: '/api/file/user/avatar',
    method: 'post',
    emit: 'admin-staff',
    fileEmit: 'admin-staff-edit',
    fileMethod: 'patch',
    cancelName: 'Отмена',
    submitName: 'Добавить'
  },
  {
    id: 'admin-staff-edit',
    title: 'Редактировать сотрудника',
    action: 'edit',
    request: '/api/user/staff',
    fileRequest: '/api/file/user/avatar',
    method: 'patch',
    emit: 'admin-staff-edit',
    fileEmit: 'admin-staff-edit',
    fileMethod: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить'
  },
  {
    id: 'admin-staff-delete',
    title: 'Удалить?',
    action: 'delete',
    request: '/api/user',
    emit: 'admin-staff-delete',
    method: 'delete',
    message: 'сотрудник',
    cancelName: 'Нет',
    submitName: 'Да'
  }
];