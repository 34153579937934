import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const staff = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'admin-staff',
      data: {
        name: '',
        email: '',
        password: '',
        file: '',
      },
      inputs: [
        { required: true, name: 'ФИО *', placeholder: 'Введите ФИО', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Email *', placeholder: 'Введите email', id: 'email', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Пароль *', placeholder: 'Введите пароль', id: 'password', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-staff-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        email: data?.email,
        avatar: data?.avatar,
        password: '',
        file: '',
      },
      inputs: [
        { required: true, name: 'ФИО *', placeholder: 'Введите ФИО', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Email *', placeholder: 'Введите email', id: 'email', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Пароль', placeholder: 'Введите пароль', id: 'password', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-staff-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}