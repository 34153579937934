import { dynamicsObject, socketResponse } from "@/interfaces";
import { Chat } from "@/interfaces/chat/chat.dto";
import { Socket } from "socket.io-client";

export function mainSocketFollow(self: any) {
  socketFollowActionChat(self);
}

export function socketJoinRoom(socket: Socket, user: string, rooms: string | string[]) {
  socket.emit('room-join', { user, room: rooms }, (data: dynamicsObject) => {
    console.log(data);
  });
  // self.socket.on("connect_error", (_err: any) => {
  //   self.$store.commit('createNotification', {
  //     status: 'error',
  //     message: 'Соединение с сервером потеряно! Обновите страницу!'
  //   });
  // });
};

export function socketLeaveRoom(socket: Socket, user: string, rooms: string | string[]) {
  socket.emit('room-leave', { user, room: rooms }, (data: dynamicsObject) => {
    console.log(data);
  });
};

function socketFollowActionChat(self: any) {
  self.socket.on('message-action', ({ status, data }: socketResponse) => {
    if (!status) return;
    self.emitter.emit('message-action', data);
      // self.$store.commit('createGeneralNotification', { creater: data.user, document: data.chat, type: 'chat', action: data.action });
  });
}